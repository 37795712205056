.wrapper {
  width: 200px;
  height: 50px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  margin-left: 100px;
  position: fixed;
  z-index: 10;
  background: white;
  right: 150px;
  top: 33px;

  &:hover {
    background: #f1e8e8;
  }
}
