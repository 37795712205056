.wordListWrapper {
  width: 90%;
  margin-right: 190px;
  margin-top: 100px;
}

.fileName {
  position: absolute;
  z-index: 10;
  top: 50px;
  left: 80px;
  font-size: 14px;
}
