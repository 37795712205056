.pageItem {
  border: 1px solid black;
  color: black;
  padding: 15px;
  cursor: pointer;

  &:hover {
    background: #f1e8e8;
  }
}

.active {
  border: 1px solid red;
  color: 1px solid red;
  background: #f1e8e8;
}

.done {
  background: #59d832;
}
