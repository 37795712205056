.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  align-items: center;
  gap: 15px;

  input {
    margin: 0 auto;
    width: 50%;
    height: 24px;
    padding: 0 20px;
  }
}
