.inputWrapper {
  margin: 3px;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 2px;
}

.firstWord {
  border: 2px solid red;
}
