.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: calc(100vh - 100px);
  position: fixed;
  right: 0;
  margin-top: 100px;
}

.legendGroup {
  display: flex;
  flex-wrap: wrap;
  max-width: 169px;
  width: 100%;
  gap: 5px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  h3 {
    font-size: 18px;
  }
}

.legendItem {
  width: 80px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h5,
  p {
    margin: 0;
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  p {
    font-size: 10px;
  }
}
